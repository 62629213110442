import React from 'react'
import './testimonial.css'
import AVTR1 from '../../assets/laptopGirlWhite_001.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';


const data = [
  {
    avatar: AVTR1,
    name: 'Tina Snow',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quidem magnam, harum tempora tenetur dolorum officiis provident accusantium, doloremque porro obcaecati totam voluptas inventore iusto? Aut accusantium deleniti nulla maxime.'
  },
  {
    avatar: AVTR1,
    name: 'Richard Shaw',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quidem magnam, harum tempora tenetur dolorum officiis provident accusantium, doloremque porro obcaecati totam voluptas inventore iusto? Aut accusantium deleniti nulla maxime.'
  },
  {
    avatar: AVTR1,
    name: 'Lianne Webb',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quidem magnam, harum tempora tenetur dolorum officiis provident accusantium, doloremque porro obcaecati totam voluptas inventore iusto? Aut accusantium deleniti nulla maxime.'
  },
  {
    avatar: AVTR1,
    name: 'Annazess',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quidem magnam, harum tempora tenetur dolorum officiis provident accusantium, doloremque porro obcaecati totam voluptas inventore iusto? Aut accusantium deleniti nulla maxime.'
  },
  {
    avatar: AVTR1,
    name: 'Aureline',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quidem magnam, harum tempora tenetur dolorum officiis provident accusantium, doloremque porro obcaecati totam voluptas inventore iusto? Aut accusantium deleniti nulla maxime.'
  },
  {
    avatar: AVTR1,
    name: 'Lucy',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quidem magnam, harum tempora tenetur dolorum officiis provident accusantium, doloremque porro obcaecati totam voluptas inventore iusto? Aut accusantium deleniti nulla maxime.'
  },
]


const Testimonial = () => {
  return (
    <section id='testimonials'>
      <h5>Review from Clients</h5>
      <h2>Testimonials</h2>
      <Swiper className="container testimonials__container"
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{clickable:true}}>
        {
          data.map(({avatar,name,review}, index) => {
            return (
              <SwiperSlide key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar} alt={name}/>
              </div>
              <h5 className='client__name'>{name}</h5>
                <small className='client__review'>{review}</small>
            </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonial