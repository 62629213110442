import React from 'react'
import './about.css'
import ME from '../../assets/auricula.png'
import { FiAward } from "react-icons/fi";
import { FaUsers } from "react-icons/fa";
import { FaRegFolder } from "react-icons/fa";

const About = () => {
  return (
    <section id='about'>
      <h5>Get to know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt='About Image' />
          </div>
        </div>  
        <div className="about__content">
          <div className="about__cards">

            <article className='about__card'>
            <FiAward className='about__icon'/>
              <h5>Experience</h5>
              <small>20 Years Experience</small>
            </article>

            <article className='about__card'>
            <FaUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>200+ Clients Worldwide</small>
            </article>
            
            <article className='about__card'>
            <FaRegFolder className='about__icon'/>
              <h5>Projects</h5>
              <small>80+ Completed</small>
            </article>
            
          </div>
          <p>
          Jelly danish marzipan toffee biscuit chupa chups cookie. Donut jelly-o cotton candy toffee cookie. Donut sweet gummi bears candy canes wafer candy canes cheesecake apple pie macaroon. Marzipan croissant gummi bears oat cake pie cheesecake.

          Marzipan chocolate caramels pastry sweet. Pastry gummies cake pudding carrot cake croissant shortbread candy canes. Donut toffee liquorice cake ice cream. Candy jelly beans liquorice sesame snaps brownie sweet roll topping halvah gingerbread. 
          </p>

          <a href='#contact' className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About