import React from 'react'
import './nav.css'
import { AiOutlineHome } from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import { IoBookOutline } from "react-icons/io5";
import { RiServiceLine } from "react-icons/ri";
import { IoPeopleOutline } from "react-icons/io5";
import { IoMdBook } from "react-icons/io";

import { BiMessageSquareDetail } from "react-icons/bi";
import {useState} from 'react'

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
      <a href='#' onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}><AiOutlineHome /></a>
      <a href='#about' onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><FiUser /></a>
      <a href='#experience' onClick={() => setActiveNav('#experiences')} className={activeNav === '#experiences' ? 'active' : ''}><IoBookOutline /></a>
      <a href='#services'onClick={() => setActiveNav('#services')} className={activeNav === '#services' ? 'active' : ''}><RiServiceLine /></a>
      <a href='#portfolio'onClick={() => setActiveNav('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''}><IoMdBook /></a>
      <a href='#testimonials'onClick={() => setActiveNav('#testimonials')} className={activeNav === '#testimonials' ? 'active' : ''}><IoPeopleOutline /></a>
      <a href='#contact' onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><BiMessageSquareDetail /></a>

    </nav>
  )
}

export default Nav