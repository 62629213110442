import React from 'react'
import './experience.css'
import { BsPatchCheck } from "react-icons/bs";

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I have</h5>
      <h2>My Experience
        <div className="container experience__container">
          <div className="experience__frontend">
            <h3>Frontend Development</h3>
            <div className="experience__content">
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>HTML</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>HTML</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>HTML</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>HTML</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>HTML</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>HTML</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
            </div>


          </div>
          <div className="experience__backend">
            <h3>Backend Development</h3>
            <div className="experience__content">
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>HTML</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>HTML</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>HTML</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>HTML</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>HTML</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
            </div>


          </div>          
        </div>
      </h2>
    </section>
  )
}

export default Experience